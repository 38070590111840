import { Swiper, Scrollbar, FreeMode } from 'swiper';

function init(containers) {
    containers.forEach((container) => {
        const sliderElements = Array.from(container.querySelectorAll('.js-slider'));
        sliderElements.forEach((el) => {
            if (Array.from(el.querySelectorAll('.swiper-slide')).length > 1) {
                const slider = new Swiper(el, {
                    modules: [Scrollbar, FreeMode],
                    slidesPerView: 'auto',
                    freeMode: true,
                    spaceBetween: 10,
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: false,
                        draggable: true,
                    },
                    // breakpoints: {
                    //     1920: {
                    //         slidesPerView: 4.2,
                    //         spaceBetween: 16,
                    //     },
                    //     1280: {
                    //         speed: 400,
                    //         slidesPerView: 4.2,
                    //         spaceBetween: 12,
                    //     },
                    //     768: {
                    //         speed: 500,
                    //         slidesPerView: 2.2,
                    //         spaceBetween: 8,
                    //     },
                    //     320: {
                    //         speed: 600,
                    //         slidesPerView: 1.1,
                    //         spaceBetween: 8,
                    //     },
                    // },
                });
            }
        });
    });
}

const _module = { init };

export default _module;
