export default function findParent(selector, startingElement) {
    let parent = startingElement;

    while (!parent.matches(selector)) {
        if (!parent.parentElement) return false;
        parent = parent.parentElement;
    }

    return parent;
}
