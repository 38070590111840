export default function saveState(instance) {
    function onChange({ detail: { nextService } }) {
        sessionStorage.setItem('current-service', nextService.id);
    }

    function init() {
        const currentSwitcherName = sessionStorage.getItem('current-services-switcher') || 'people';

        if (instance.name === currentSwitcherName) {
            const currentServiceName = sessionStorage.getItem('current-service');
            const currentService = instance.services.find(service => service.id === currentServiceName);

            if (currentServiceName && currentService) {
                instance.navigateToServiceInstantly(currentServiceName);
            }
        }

        instance.on('change', onChange);
    }

    function destroy() {
        instance.off('change', onChange);
        sessionStorage.removeItem('active-service');
    }

    init();

    return { destroy };
}
