function relayoutDownloadAppList() {
    const appList = document.querySelector('.js-download-app-list');
    const mobileAppListContainer = document.querySelector('.js-mobile-download-app-list-container');

    if (appList) {
        if (mobileAppListContainer) {
            const clone = appList.cloneNode(true);
            mobileAppListContainer.appendChild(clone);
        }

        appList.classList.add('download-app-list--desktop');
    }
}

function relayoutLang() {
    const lang = document.querySelector('.js-lang');
    const mobileLangContainer = document.querySelector('.js-mobile-lang-container');

    if (lang) {
        if (mobileLangContainer) {
            const clone = lang.cloneNode(true);
            mobileLangContainer.appendChild(clone);
        }

        lang.classList.add('lang--desktop');
    }
}

function relayoutSoc() {
    const socList = document.querySelector('.js-soc-list');
    const mobileSocListContainer = document.querySelector('.js-mobile-soc-list-container');

    if (socList) {
        if (mobileSocListContainer) {
            const clone = socList.cloneNode(true);
            mobileSocListContainer.appendChild(clone);
        }

        socList.classList.add('soc-list--desktop');
    }
}

function init() {
    relayoutDownloadAppList();
    relayoutLang();
    relayoutSoc();
}

const _module = { init };

export default _module;
