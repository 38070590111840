import { TimelineLite, TweenLite } from 'gsap/all';
import dragscroll from 'dragscroll';

import Modal from '../../../../modules/modal';
import { triggerCustomEvent, listenOnce, findParent } from '../../../utils';
import { TEXTS, lang } from '../../cursor';

export default function hold(instance) {
    let isHeld = false;
    let done = false;
    let timeout;
    let holdingRAF;
    let holdingReleaseRAF;
    let currentTime = 0;
    const duration = 1500;
    const ACTIVE_CONTAINER_CLASS = 'screen-services-list-container--active';
    const containers = Array.from(document.querySelectorAll('.js-screen-services-container'));
    const cursorText = document.querySelector('.js-cursor-text');

    function switchServicesView() {
        if (containers.length === 0 || Modal.isOpen) return;

        const activeContainer = containers.find((container) => container.classList.contains(ACTIVE_CONTAINER_CLASS));

        if (!activeContainer) return;

        const items = Array.from(
            document.querySelectorAll('.screen-services-list-container--active .services-list-item'),
        );
        const switcher = activeContainer.querySelector('.screen-services__switcher');
        const list = activeContainer.querySelector('[data-view]');
        const activeItem = list.querySelector('.services-list-item--active');
        const activeItemImgContainer = activeItem.querySelector('.service-item__img-container');
        const activeItemImg = activeItemImgContainer.querySelector('img');

        if (!list) return;
        const activeList = document.querySelector('.screen-services-list-container--active [data-view]');

        function applyListView() {
            if (!activeItemImg) return;

            if (cursorText) {
                cursorText.textContent = TEXTS.ONE_SERVICE[lang];
            }

            const leaveTl = new TimelineLite({
                onComplete: () => {
                    leaveTl.kill();
                },
            });

            leaveTl
                .to(activeItem || {}, 1, { xPercent: -100 })
                .to(activeItem.querySelector('.js-service-item') || {}, 1, { xPercent: 100 }, '-=1')
                .to(
                    switcher || {},
                    1,
                    {
                        opacity: 0,
                        onComplete: () => {
                            list.setAttribute('data-view', 'list');
                            list.classList.add('dragscroll');
                            dragscroll.reset();
                            const serviceItems = items.map((item) => item.querySelector('.js-service-item') || '');
                            switcher.style.display = 'none';
                            TweenLite.set([activeItem, activeItem.querySelector('.js-service-item')], {
                                clearProps: 'all',
                            });
                            TweenLite.set([...items, ...serviceItems], { clearProps: 'transform' });
                            triggerCustomEvent(activeItem, 'leave');
                        },
                    },
                    '-=1',
                );

            const itemsToAnimate = items.filter((_, i) => i < 5);

            listenOnce(activeItem, 'leave', () => {
                const enterTl = new TimelineLite({
                    onComplete: () => {
                        enterTl.kill();
                        if (activeList) {
                            activeList.style.pointerEvents = '';
                        }
                    },
                });

                enterTl.staggerFromTo(itemsToAnimate, 1.2, { x: -40, opacity: 0 }, { x: 0, opacity: 1 }, 0.25);
            });
        }

        function applyCardView() {
            if (!activeItemImg) return;

            if (cursorText) {
                cursorText.textContent = TEXTS.ALL_SERVICES[lang];
            }

            const leaveTl = new TimelineLite({
                onComplete: () => {
                    if (list.scrollTo) {
                        list.scrollTo(0, 0);
                    }

                    list.setAttribute('data-view', 'card');
                    list.classList.remove('dragscroll');
                    dragscroll.reset();
                    switcher.style.display = '';
                    TweenLite.set(items, { clearProps: 'all' });
                    triggerCustomEvent(activeItem, 'leave');
                    leaveTl.kill();
                },
            });

            leaveTl.staggerTo(items, 0.7, { x: 40, opacity: 0 }, 0.2);

            listenOnce(activeItem, 'leave', () => {
                const enterTl = new TimelineLite({
                    onComplete: () => {
                        enterTl.kill();
                        if (activeList) {
                            activeList.style.pointerEvents = '';
                        }
                    },
                });

                enterTl
                    .fromTo(activeItem || {}, 1, { xPercent: -100 }, { xPercent: 0 })
                    .fromTo(
                        activeItem.querySelector('.js-service-item') || {},
                        1,
                        { xPercent: 100 },
                        { xPercent: 0 },
                        '-=1',
                    )
                    .to(switcher || {}, 1, { opacity: 1 }, '-=1');
            });
        }

        switch (list.dataset.view) {
            case 'card':
                applyListView();
                break;
            case 'list':
                applyCardView();
                break;
            default:
                break;
        }
    }

    function onHoldingRelease() {
        if (!(instance.screenNav && instance.screenNav.activeScreen.id === 'services')) {
            return;
        }

        if (!isHeld && currentTime > 0) {
            currentTime -= 16;

            if (currentTime < 0) {
                currentTime = 0;

                if (done) {
                    done = false;
                }
            }

            holdingReleaseRAF = requestAnimationFrame(onHoldingRelease);
        }
    }

    function clearHoldingState() {
        const activeList = document.querySelector('.screen-services-list-container--active [data-view]');
        clearTimeout(timeout);
        cancelAnimationFrame(holdingRAF);
        isHeld = false;
        const { cursor } = instance;

        if (cursor) {
            cursor.container.classList.remove('cursor--holding');
        }

        onHoldingRelease();

        if (activeList) {
            activeList.style.pointerEvents = '';
        }
    }

    function onHolding() {
        if (!(instance.screenNav && instance.screenNav.activeScreen.id === 'services')) {
            return;
        }

        if (isHeld) {
            if (currentTime < duration) {
                currentTime += 16;

                if (currentTime > duration) {
                    currentTime = duration;
                }

                holdingRAF = requestAnimationFrame(onHolding);
            } else {
                const activeList = document.querySelector('.screen-services-list-container--active [data-view]');
                isHeld = false;
                done = true;

                if (activeList) {
                    setTimeout(() => {
                        activeList.style.pointerEvents = 'none';
                    }, 10);
                }

                if (instance.cursor) {
                    instance.cursor.container.classList.add('cursor--hold-complete');
                    setTimeout(() => {
                        instance.cursor.container.classList.remove('cursor--hold-complete');
                    }, 300);
                }

                if (holdingRAF) {
                    cancelAnimationFrame(holdingRAF);
                }

                switchServicesView();
                clearHoldingState();
            }
        }
    }

    function startHolding(event) {
        const activeList = document.querySelector('.screen-services-list-container--active [data-view]');

        if (
            !(instance.screenNav && instance.screenNav.activeScreen.id === 'services' && instance.isActive) ||
            Modal.isOpen ||
            isHeld ||
            event.which !== 1 ||
            (activeList.classList.contains('dragscroll') && findParent('.dragscroll', event.target))
        ) {
            return;
        }

        timeout = setTimeout(() => {
            if (activeList) {
                activeList.style.pointerEvents = 'none';
            }

            cancelAnimationFrame(holdingReleaseRAF);
            isHeld = true;
            const { cursor } = instance;

            if (cursor) {
                cursor.container.classList.add('cursor--holding');
            }

            onHolding();
        }, 200);
    }

    function init() {
        document.addEventListener('mousedown', startHolding);
        document.addEventListener('mouseup', clearHoldingState);
    }

    function destroy() {
        clearHoldingState();
        document.removeEventListener('mousedown', startHolding);
        document.removeEventListener('mouseup', clearHoldingState);
    }

    init();

    return { destroy };
}
