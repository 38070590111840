// import gsap from 'gsap/all';
import { TweenLite, TimelineMax } from 'gsap/all';
import { triggerCustomEvent } from '../../utils';

// eslint-disable-next-line max-len
export const hoverableSelector = 'a, button, input[type="submit"], .js-custom-hoverable';
export const lang = document.documentElement.getAttribute('lang').toUpperCase();

export const TEXTS = {
    ALL_SERVICES: {
        RU: 'Нажмите и удерживайте для просмотра всех услуг',
        EN: 'Press and hold to view all services',
        DE: 'Gedrückt halten, um alle Dienste anzuzeigen',
    },
    ONE_SERVICE: {
        RU: 'Нажмите и удерживайте для просмотра каждой услуги',
        EN: 'Press and hold to view each service',
        DE: 'Gedrückt halten, um die einzelnen Dienste anzuzeigen',
    },
};

export default class Cursor {
    constructor() {
        this.clientX = 0;
        this.clientY = 0;
        this.outerCursorBox = {};
        this.linkItems = [];

        this.createCursor();
        document.documentElement.classList.add('no-system-cursor');

        // Bind methods
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.unveilCursor = this.unveilCursor.bind(this);
        this.getMouseCoords = this.getMouseCoords.bind(this);
        this.render = this.render.bind(this);
    }

    on(eventName, fn) {
        this.container.addEventListener(eventName, fn);
    }

    off(eventName, fn) {
        this.container.removeEventListener(eventName, fn);
    }

    trigger(eventName, options) {
        triggerCustomEvent(this.container, eventName, options);
    }

    init() {
        this.linkItems = Array.from(document.querySelectorAll(hoverableSelector));
        this.outerCursorBox = this.outerCursor.getBoundingClientRect();
        this.outerCursorBox.halfWidth = this.outerCursorBox.width / 2;
        this.outerCursorBox.halfHeight = this.outerCursorBox.height / 2;
        document.addEventListener('pointermove', this.unveilCursor);
        document.addEventListener('pointermove', this.getMouseCoords);
        this.initHovers();
    }

    destroy() {
        TweenLite.ticker.removeEventListener('tick', this.render);
        document.removeEventListener('pointermove', this.unveilCursor);
        document.removeEventListener('pointermove', this.getMouseCoords);
        TweenLite.set(this.outerCursor, { scale: 1 });
        TweenLite.set(this.innerCursor, { opacity: 1 });
        this.destroyHovers();
    }

    createCursor() {
        this.container = document.createElement('div');
        this.container.className = 'cursor js-cursor';

        const cursorText = document.createElement('div');
        cursorText.className = 'cursor-text js-cursor-text';
        cursorText.textContent = TEXTS.ALL_SERVICES[lang];
        this.container.appendChild(cursorText);

        this.innerCursor = document.createElement('div');
        this.innerCursor.className = 'circle-cursor circle-cursor--inner';

        this.circleCursor = document.createElement('div');
        this.circleCursor.className = 'cursor-outline js-cursor-outline';

        this.outerCursor = document.createElement('div');
        this.outerCursor.className = 'circle-cursor circle-cursor--outer';

        this.outerCursorSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        [
            ['viewBox', '0 0 100 100'],
            ['width', '30'],
            ['height', '30'],
        ].forEach(attr => {
            const [name, value] = attr;
            this.outerCursorSvg.setAttribute(name, value);
        });

        const svgCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        [
            ['r', '50'],
            ['cx', '50'],
            ['cy', '50'],
            ['fill', 'none'],
            ['stroke', '#000'],
        ].forEach(attr => {
            const [name, value] = attr;
            svgCircle.setAttribute(name, value);
        });
        svgCircle.style.setProperty('--path-length', '313.65');
        this.outerCursorSvg.appendChild(svgCircle);

        this.outerCursorX = window.innerWidth / 2;
        this.outerCursorY = window.innerHeight / 2;

        this.outerCursor.appendChild(this.outerCursorSvg);
        this.container.appendChild(this.innerCursor);
        this.container.appendChild(this.outerCursor);
        document.body.appendChild(this.container);
        document.body.appendChild(this.circleCursor);

        TweenLite.set(this.container, { visibility: 'hidden' });
        TweenLite.set(this.circleCursor, { visibility: 'hidden' });
    }

    reinit() {
        this.destroyHovers();
        this.linkItems = Array.from(document.querySelectorAll(hoverableSelector));
        this.initHovers();
    }

    getMouseCoords(event) {
        this.clientX = event.clientX;
        this.clientY = event.clientY;
    }

    handleMouseEnter() {
        TweenLite.to(this.innerCursor, 0.5, { opacity: 0.65, scale: 1.5 });
        this.circleCursor.classList.add('is-active');
    }

    handleMouseLeave() {
        TweenLite.to(this.innerCursor, 0.5, { opacity: 1, scale: 1 });
        this.circleCursor.classList.remove('is-active');
    }

    initHovers(parentElement) {
        const links = parentElement ? Array.from(parentElement.querySelectorAll(hoverableSelector)) : this.linkItems;

        links.forEach((item) => {
            if (item.classList && item.classList.contains('js-custom-hoverable--ignore')) {
                return;
            }

            item.removeEventListener('mouseenter', this.handleMouseEnter);
            item.addEventListener('mouseenter', this.handleMouseEnter);
            item.removeEventListener('mouseleave', this.handleMouseLeave);
            item.addEventListener('mouseleave', this.handleMouseLeave);
        });
    }

    destroyHovers(parentElement) {
        const links = parentElement ? Array.from(parentElement.querySelectorAll(hoverableSelector)) : this.linkItems;
        links.forEach((item) => {
            item.removeEventListener('mouseenter', this.handleMouseEnter);
            item.removeEventListener('mouseleave', this.handleMouseLeave);
        });
    }

    render() {
        this.container.style.transform = `translate3d(${this.clientX}px, ${this.clientY}px, 0)`;
        this.circleCursor.style.transform = `translate3d(${this.clientX}px, ${this.clientY}px, 0)`;
    }

    unveilCursor() {
        document.removeEventListener('pointermove', this.unveilCursor);
        this.innerCursor.style.transform = `translate3d(${this.clientX}px, ${this.clientY}px, 0)`;
        TweenLite.ticker.addEventListener('tick', this.render);
        TweenLite.set(this.container, { clearProps: 'visibility' });
        TweenLite.set(this.circleCursor, { clearProps: 'visibility' });
    }
}
