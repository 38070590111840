import { fetchSlimSelect } from '../dynamic-modules';

export default async function initSelects(container = document) {
    const { default: SlimSelect } = await fetchSlimSelect();
    const selects = Array.from(container.querySelectorAll('.js-select'));
    selects.forEach((element) => {
        const instance = new SlimSelect({
            select: element,
            showSearch: false,
        });
    });
}
