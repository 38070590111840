import { TimelineLite, Power2, Power3, TweenLite } from 'gsap/all';
import Hammer from 'hammerjs';
import { KEYCODES, triggerCustomEvent, listenOnce } from '../../utils';
import { hold, saveState } from './deps';

export const ACTIVE_BTN_CLASS = 'services-switcher-btn--active';
export const ACTIVE_SERVICE_CLASS = 'services-list-item--active';
export const ACTIVE_SERVICES_CONTAINER_CLASS = 'screen-services-list-container--active';

const defaultOptions = {
    dependencies: [hold, saveState],
};

export default class ServicesSwitcher {
    constructor(name, options) {
        if (!name) {
            throw new Error('[ServicesSwitcher] "name" property should be provided.');
        }

        this.options = { ...defaultOptions, ...options };
        this.dependencies = [];
        this.isActive = true;
        this.name = name;
        this.container = document.querySelector(`.js-services-list--${name}`);
        this.setActiveState(this.container.classList.contains(ACTIVE_SERVICES_CONTAINER_CLASS));
        this.buttons = Array.from(this.container.querySelectorAll('[data-service-for]'));
        this.servicesScreen = document.querySelector('.screen-services');
        const services = Array.from(this.container.querySelectorAll('.js-services-list-item'));
        this.total = services.length;
        this.hammer = new Hammer(this.container.querySelector('.js-services-list'));
        this.hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });

        this.services = services.map((service) => {
            const { id } = service;
            return {
                element: service,
                contentElement: service.querySelector('.js-service-item'),
                titleElement: service.querySelector('.js-service-title'),
                img: service.querySelector('.js-service-img'),
                id,
                navElement: this.buttons.find((btn) => btn.dataset.serviceFor === id),
            };
        });

        this.activeService = this.services.find((service) => service.element.classList.contains(ACTIVE_SERVICE_CLASS));

        this.tl = new TimelineLite({ ease: Power3.easeOut });

        this.options.dependencies.forEach((dep) => {
            const depObj = dep(this);
            this.dependencies.push(depObj);
        });

        this.navigateToService = this.navigateToService.bind(this);
        this.onSwipe = this.onSwipe.bind(this);
        this.onKeydown = this.onKeydown.bind(this);

        this.init();
    }

    init() {
        document.addEventListener('keydown', this.onKeydown);
        this.buttons.forEach((btn) => {
            btn.addEventListener('click', () => {
                if (btn.classList.contains(ACTIVE_BTN_CLASS)) {
                    return;
                }

                this.navigateToService(btn.dataset.serviceFor);
            });
        });

        this.hammer.on('swipe', this.onSwipe);

        this.services.forEach((service) => {
            service.contentElement.addEventListener('mouseenter', () => {
                TweenLite.to(service.img || {}, 1, { scale: 1 });
            });

            service.contentElement.addEventListener('mouseleave', () => {
                TweenLite.to(service.img || {}, 1, { scale: 1.05 });
            });
        });
    }

    destroy() {
        this.dependencies.forEach((dep) => dep.destroy && dep.destroy());
    }

    on(eventName, fn) {
        this.container.addEventListener(eventName, fn);
    }

    one(eventName, fn) {
        listenOnce(this.container, eventName, fn);
    }

    off(eventName, fn) {
        this.container.removeEventListener(eventName, fn);
    }

    trigger(eventName, options) {
        triggerCustomEvent(this.container, eventName, options);
    }

    setActiveState(flag) {
        this.isActive = flag;
        this.container.classList[flag ? 'add' : 'remove'](ACTIVE_SERVICES_CONTAINER_CLASS);
    }

    navigateToServiceInstantly(id) {
        const currentService = this.activeService;
        const nextService = this.services.find((service) => service.id === id);

        if (!nextService) {
            throw new Error(`[Services Switcher] Service with id "${id}" not found.`);
        }

        currentService.element.classList.remove(ACTIVE_SERVICE_CLASS);
        nextService.element.classList.add(ACTIVE_SERVICE_CLASS);

        currentService.navElement.classList.remove(ACTIVE_BTN_CLASS);
        nextService.navElement.classList.add(ACTIVE_BTN_CLASS);

        this.activeService = nextService;
    }

    navigateToService(id) {
        if (
            !this.servicesScreen.classList.contains('screen--active') &&
            window.matchMedia('(min-width: 1025px)').matches
        ) {
            return;
        }

        const currentService = this.activeService;
        const nextService = this.services.find((service) => service.id === id);
        const duration = 1;

        this.tl.totalProgress(1).kill();

        this.trigger('change', { currentService, nextService });

        this.tl
            .to(this.activeService.element || {}, duration, {
                xPercent: 100,
                onComplete: () => {
                    currentService.element.classList.remove(ACTIVE_SERVICE_CLASS);
                    nextService.element.classList.add(ACTIVE_SERVICE_CLASS);
                    this.tl.set(currentService.titleElement, { clearProps: 'all' });
                },
            })
            .to(currentService.titleElement || {}, duration / 2, { opacity: 0 }, `-=${duration}`)
            .to(currentService.contentElement || {}, duration, { xPercent: -100 }, `-=${duration}`)
            .fromTo(nextService.element || {}, duration, { xPercent: -100 }, { xPercent: 0 })
            .fromTo(
                nextService.img || {},
                duration,
                { scale: 1 },
                {
                    scale: 1.05,
                    ease: Power2.easeOut,
                },
                `-=${duration}`,
            )
            .fromTo(nextService.contentElement || {}, duration, { xPercent: 100 }, { xPercent: 0 }, `-=${duration}`);

        currentService.navElement.classList.remove(ACTIVE_BTN_CLASS);
        nextService.navElement.classList.add(ACTIVE_BTN_CLASS);
        this.activeService = nextService;
    }

    getNextServiceId() {
        const nextIndex = (this.services.findIndex((service) => service.id === this.activeService.id) + 1) % this.total;
        const { id } = this.services[nextIndex];
        return id;
    }

    getPrevServiceId() {
        const nextIndex =
            (this.services.findIndex((service) => service.id === this.activeService.id) - 1 + this.total) % this.total;
        const { id } = this.services[nextIndex];
        return id;
    }

    navigateToNextService() {
        this.navigateToService(this.getNextServiceId());
    }

    navigateToPrevService() {
        this.navigateToService(this.getPrevServiceId());
    }

    onKeydown(event) {
        if (!this.isActive) {
            return;
        }

        if (event.shiftKey || event.metaKey || event.altKey || event.ctrlKey) return;

        switch (event.keyCode) {
            case KEYCODES.ARROW_RIGHT:
                this.navigateToNextService();
                break;
            case KEYCODES.ARROW_LEFT:
                this.navigateToPrevService();
                break;
            default:
                break;
        }
    }

    onSwipe(event) {
        const viewItem = document.querySelector('.screen-services-list-container--active [data-view]');

        if (viewItem) {
            const { view } = viewItem.dataset;
            if (view === 'list') return;
        }

        if (event.deltaX < 0) {
            this.navigateToNextService();
        } else {
            this.navigateToPrevService();
        }
    }
}
