import axios from 'axios';
import { render, html } from 'lit-html';
import { keyed } from 'lit-html/directives/keyed.js';
import { TweenLite, TimelineLite } from 'gsap/all';
import { validateInput } from '../../modules/validator/index';

const fetchIMask = async () => import('imask').then((m) => m.default);

async function initPhoneMask(input, dialCode = '7') {
    const IMask = await fetchIMask();
    const imask = IMask(input, {
        mask: `+{${dialCode}} (000)-000-00-00`,
        prepare: (appended, masked) => {
            if (appended === '8' && masked.value === '') {
                return '';
            }
            return appended;
        },
    });
}

document.addEventListener('DOMContentLoaded', async () => {
    const quizWrapper = document.querySelector('.js-quiz__container-content');
    const container = document.querySelector('.js-quiz__container');
    if (container) {
        const templateContainer = container.querySelector('.js-quiz-questions__form-container');
        const progressbar = container.querySelector('.js-quiz__progressbar');

        let data;
        if (container) {
            data = JSON.parse(container.dataset.questions);
        }

        let responseMessage;

        if (!data.length) {
            return;
        }
        const drafts = data.map((question) => {
            return {
                ...question,
                value: question.inputs[0]?.type === 'checkbox' ? [] : '*',
            };
        });

        if (container && drafts) {
            let counter = 1;
            let currentValue = drafts[counter - 1].value;
            const maxQuestions = drafts.length;
            let progressQuiz = 0;

            const containerTemplateFinish = (response) => html`
                <div class="quiz-finish__container">
                    <div class="h2 quiz-finish__title">
                        Спасибо за ваше обращение. Наши специалисты свяжутся с вами в кратчайшее время.
                    </div>
                </div>
            `;

            const textareaTemplate = (input, currentCounter) => {
                setTimeout(() => {
                    isTextArea(input);
                }, 10);
                return html`
                    ${keyed(
                        currentCounter,
                        html`
                            <textarea
                                name=""
                                value="${input.value ? input.value : ''}"
                                class="quiz-textarea js-quiz-textarea quiz-textarea-hidden text-s"
                                @change="${(event) => {
                                    setAnotherAnswer(input, event.target.value);
                                }}"
                            >
                                ${input.value ? (input.value = input.value.trim()) : ''}
                            </textarea
                            >
                        `,
                    )}
                `;
            };

            const liTemplate = (input, currentCounter) => {
                return html`
                    <li class="questions__item js-questions__item">
                        <label class="questions__item-container">
                            ${keyed(
                                currentCounter,
                                html`
                                    <input
                                        type="${input.type}"
                                        name="${input.name}"
                                        .value="${input.value}"
                                        ?checked="${input.type === 'checkbox'
                                            ? drafts[currentCounter - 1].value.includes(input.value)
                                            : input.value === drafts[currentCounter - 1].value}"
                                        @change=${(event) => {
                                            validError.classList.remove('is-error');
                                            if (input.type === 'checkbox') {
                                                isTextArea(input);
                                                if (event.target.checked) {
                                                    if (input.another) {
                                                        setAnotherAnswer(input, currentValue);
                                                    } else {
                                                        currentValue.push(event.target.value);
                                                    }
                                                } else {
                                                    if (input.another) {
                                                        removeAnotherAnswer(input, currentValue);
                                                    } else {
                                                        currentValue = currentValue.filter(
                                                            (value) => value !== event.target.value,
                                                        );
                                                    }
                                                }
                                            } else {
                                                isTextArea(input);
                                                if (input.another) {
                                                    setAnotherAnswer(input, event.target);
                                                } else {
                                                    currentValue = event.target.value;
                                                }
                                            }
                                        }}
                                        ?required="${['text', 'phone', 'email'].includes(input.type) ? 'required' : ''}"
                                        class="${['radio', 'checkbox'].includes(input.type)
                                            ? 'visually-hidden js-validate--checkbox questions__item-checkbox'
                                            : ''} ${'phone'.includes(input.type)
                                            ? 'js-mask--phone js-validate--phone'
                                            : ''} ${'email'.includes(input.type)
                                            ? 'js-validate--email'
                                            : ''} ${input.another
                                            ? 'js-questions-input-textarea'
                                            : ''} questions-input js-questions-input js-questions__item-checkbox"
                                    />
                                `,
                            )}
                            ${input.text
                                ? html`<span class="questions__item-title js-questions__item-title text-s"
                                      >${input.text}</span
                                  >`
                                : ''}
                        </label>
                        ${input.another ? textareaTemplate(input, currentCounter) : ''}
                    </li>
                `;
            };

            const containerTemplate = (questionContent) =>
                html`
                    ${keyed(
                        questionContent.id,
                        html`
                            <div class="quiz-questions__form-content">
                                <p class="quiz__desc text-s js-quiz__desc" data-question-id="${questionContent.id}">
                                    ${questionContent.question}
                                </p>
                                <span class="quiz-valid__error text-s js-quiz-valid__error"
                                    >${pastTextErrorValid(questionContent)}</span
                                >
                                <ul class="quiz-questions__list list-unstyled">
                                    ${questionContent.inputs.map((input) => liTemplate(input, counter))}
                                </ul>
                            </div>
                            <div class="quiz-error js-quiz-error" hidden>Что то пошло не так, повторите попытку</div>
                        `,
                    )}
                `;

            const renderQuestion = (index) => {
                render(containerTemplate(drafts[index]), templateContainer);
            };

            const nextBtn = container.querySelector('.js-quiz-questions__button-next');
            const prevBtn = container.querySelector('.js-quiz-questions__button-prev');
            nextBtn.addEventListener('click', nextQuestion);
            prevBtn.addEventListener('click', prevQuestion);

            renderQuestion(counter - 1);
            let validError = templateContainer.querySelector('.js-quiz-valid__error');

            function isTextArea(question) {
                //rename
                const textareaElem = templateContainer.querySelector('.js-quiz-textarea');
                if (textareaElem) {
                    const liElem = textareaElem.parentNode;
                    const input = liElem.querySelector('.js-questions-input');
                    if (question.type === 'radio') {
                        if (question.another && question.value !== '') {
                            isFull();
                        } else if (question.another && input.checked) {
                            textareaElem.value = textareaElem.value.trim();
                            isFull();
                        } else {
                            textareaElem.value = textareaElem.value.trim();
                            isHide();
                        }
                    } else if (question.type === 'checkbox') {
                        if (input.checked) {
                            isFull();
                            textareaElem.value = textareaElem.value.trim();
                        } else {
                            isHide();
                            textareaElem.value = textareaElem.value.trim();
                        }
                    }

                    function isFull() {
                        const liElemHeight = liElem.offsetHeight;
                        TweenLite.to(textareaElem, 0.3, {
                            height: liElemHeight,
                            y: liElemHeight,
                            opacity: 1,
                            pointerEvents: 'all',
                        });
                    }

                    function isHide() {
                        TweenLite.to(textareaElem, 0.3, {
                            y: 0,
                            pointerEvents: 'none',
                            height: 1,
                            opacity: 0,
                        });
                    }
                }
            }

            function setAnotherAnswer(question, value) {
                const answer = templateContainer.querySelector('.js-quiz-textarea');
                if (question.type === 'checkbox') {
                    if (answer.value !== '') {
                        question.value = value;
                        currentValue.push(answer.value);
                    }
                } else {
                    question.value = answer.value;
                    currentValue = answer.value;
                }
            }

            function removeAnotherAnswer(question, value) {
                question.value = '';
                const answers = drafts[counter - 1];
                let currentValues = [];
                currentValue.forEach((value) => {
                    answers.inputs.forEach((el) => {
                        if (!el.another && value === el.value) {
                            currentValues.push(el.value);
                        }
                    });
                });

                currentValue = currentValues.slice();
            }

            // function setPlaceholder(object) {
            //     console.log(object);

            //     switch (object.type) {
            //         case 'text':
            //             console.log('text');
            //             // return ''
            //             break;
            //         case 'phone':
            //             console.log('phone');
            //             return '+7 (900) 123-45-67';
            //             break;
            //         case 'text':
            //             console.log('text');
            //             // return ''
            //             break;

            //         default:
            //             break;
            //     }
            // }

            function nextQuestion() {
                let validError = templateContainer.querySelector('.js-quiz-valid__error');
                const answers = Array.from(templateContainer.querySelectorAll('.js-questions__item'));
                let inputs = [];
                if (answers) {
                    answers.forEach((elem) => {
                        const answer = elem.querySelector('.js-questions-input');
                        if (answer) {
                            inputs.push(answer);
                        }
                    });
                }

                if (templateContainer && inputs) {
                    let isValidateTextArea = true;
                    let validate = Array.from(inputs).some((input) => {
                        return validateInput(input);
                    });

                    inputs.forEach((input) => {
                        const liElement = input.closest('.js-questions__item');
                        const textareaelement = liElement.querySelector('.js-quiz-textarea');
                        if (textareaelement) {
                            if (textareaelement.value !== '' && input.checked) {
                                isValidateTextArea = true;
                                validError.classList.remove('is-error');
                            } else if (!input.checked) {
                                isValidateTextArea = true;
                                validError.classList.remove('is-error');
                            } else {
                                isValidateTextArea = false;
                            }
                        }
                    });

                    if (isValidateTextArea && validate) {
                        validError.classList.remove('is-error');

                        if (counter === maxQuestions && nextBtn.disabled === false) {
                            nextBtn.disabled = true;
                        }

                        if (counter < maxQuestions) {
                            drafts[counter - 1].value = currentValue;

                            counter++;
                            currentValue = drafts[counter - 1].value;

                            updateRender('next');
                            changeProgress();
                        } else {
                            drafts[counter - 1].value = currentValue;
                            changeProgress();
                            submit();
                        }
                    } else {
                        validError.classList.add('is-error');
                    }
                }
            }

            function prevQuestion() {
                if (counter > 1) {
                    counter--;
                    currentValue = drafts[counter - 1].value;
                    updateRender('prev');
                    changeProgress(progressQuiz);
                }

                if (counter === 1 && prevBtn.disabled === false) {
                    prevBtn.disabled = true;
                }
            }
            // вывод сообщений в зависимости от типа инпута
            function pastTextErrorValid(obj) {
                let type = obj.inputs[0].type;
                let text;

                switch (type) {
                    case 'radio':
                        text = 'Выберите/заполните один из вариантов';
                        break;
                    case 'checkbox':
                        text = 'Выберите/заполните один или несклько вариантов';
                        break;
                    case 'text':
                        text = 'Пожалуйста, заполните поле.';
                        break;
                    case 'phone':
                        text = 'Пожалуйста, введите номер телефона.';
                        break;
                    case 'email':
                        text = 'Пожалуйста, введите почту.';
                        break;

                    default:
                        break;
                }

                return text;
            }
            function updateRender(direction) {
                if (direction) {
                    animateTemplates(templateContainer, direction);
                }
                nextBtn.removeAttribute('disabled');
                prevBtn.removeAttribute('disabled');
            }
            // валидация
            function addValidateInputs() {
                const inputs = templateContainer.querySelectorAll('input');
                inputs.forEach((el) => {
                    let type = el.getAttribute('type');
                    if (type === 'phone') {
                        initPhoneMask(el);
                    }
                });
            }
            //прогрессбар
            function changeProgress() {
                progressQuiz = Math.round(((counter - 1) / maxQuestions) * 100);
                progressbar.style.width = `${progressQuiz}%`;
            }
            async function submit() {
                const response = await axios.post('/local/ajax/quiz.php', {
                    drafts,
                });

                if (response) {
                    animateSeparators();
                    console.log(response);

                    container.classList.add('quiz-finish');
                    render(containerTemplateFinish(response), templateContainer);
                    progressbar.style.width = `${100}%`;
                }
            }
            function animateTemplates(template, direction) {
                if (direction === 'next') {
                    TweenLite.to(template, 0.3, {
                        x: -1000,
                        opacity: 0,
                        onComplete: () => {
                            renderQuestion(counter - 1);
                            TweenLite.fromTo(template, 0.3, { x: 1000, opacity: 0 }, { x: 0, opacity: 1 });
                            addValidateInputs();
                        },
                    });
                } else if (direction === 'prev') {
                    TweenLite.to(template, 0.3, {
                        x: 1000,
                        opacity: 0,
                        onComplete: () => {
                            renderQuestion(counter - 1);
                            TweenLite.fromTo(template, 0.3, { x: -1000, opacity: 0 }, { x: 0, opacity: 1 });
                            addValidateInputs();
                        },
                    });
                }
            }

            function animateSeparators() {
                const titles = Array.from(document.querySelectorAll('.quiz__title'));
                const separators = Array.from(document.querySelectorAll('.js-quiz-separator'));
                if (separators && titles.length > 0) {
                    const separatorTop = separators.find((separator) =>
                        separator.classList.contains('quiz-separator--top'),
                    );
                    const separatorBot = separators.find((separator) =>
                        separator.classList.contains('quiz-separator--bot'),
                    );

                    titles.forEach((title) => {
                        TweenLite.to(title, 0.2, {
                            opacity: 0,
                        });
                    });

                    if (separatorTop && separatorBot) {
                        TweenLite.to(separatorTop, 0.4, {
                            left: '50%',
                            opacity: 1,
                        });
                        TweenLite.to(separatorBot, 0.4, {
                            right: '50%',
                            opacity: 1,
                        });
                    }
                }
            }
        }
    }
});
