export default function initContentList() {
    const priceTypeSelect = document.querySelector('.js-price-types');
    const priceServicesSelect = document.querySelector('.js-price-services');

    if (!priceTypeSelect || !priceServicesSelect) return;

    priceTypeSelect.addEventListener('change', () => {
        priceServicesSelect.value = '';
    })
}
